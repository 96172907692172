import { Icon } from '@nrk/yr-icons';
import { ITemperatureLayerEvent, TemperatureLayer, YrMap } from '@nrk/yr-map';
import { useEffect, useState } from 'react';
import { track } from '../../lib/analytics/track';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { ILocation } from '../../model/location';
import { IPoint } from '../../model/point';
import { createLocationPopupData, ILocationsNearby } from '../MapPopup/helpers/data';
import { MapPopup } from '../MapPopup/MapPopup';
import { MapPopup__Loader } from '../MapPopup/MapPopup__Loader';
import { Temperature } from '../Temperature/Temperature';

interface IProps {
  map: YrMap;
  layer: TemperatureLayer;
}

export function MapTypeTemperature__Popup(props: IProps) {
  const { layer, map } = props;
  const localeCode = useLocaleCode();

  const [loaderData, setLoaderData] = useState<{
    pin: 'primary' | 'secondary';
    point: IPoint;
  }>();
  const [popup, setPopup] = useState<{
    showPopup: boolean;
    isClosed: boolean;
  }>({ showPopup: false, isClosed: false });
  const [popupData, setPopupData] = useState<{
    location?: ILocation;
    locationsNearby: ILocationsNearby[];
    point: IPoint;
    temperature: number;
    pin: 'primary' | 'secondary';
  }>();

  useEffect(() => {
    if (layer == null) {
      return;
    }

    function handleEvent(event: ITemperatureLayerEvent) {
      if (event.type === 'temperature-layer:click') {
        const { location, locationsNearby } = createLocationPopupData({
          location: event.location,
          locationsNearby: event.locationsNearby,
          localeCode
        });
        setPopup(
          popup.isClosed === false ? { showPopup: true, isClosed: false } : { showPopup: false, isClosed: false }
        );
        setLoaderData(undefined);
        setPopupData({
          location: location,
          locationsNearby,
          temperature: event.temperature.temperature,
          pin: event.pin,
          point: event.point
        });

        track.event({ category: 'map_temperature', action: 'click_in_map' });
      }

      // We only want to change the data in the popup if it has been opened
      // or else it will render at position x:0 y:0 if a user e.g starts
      // to play and changes back and forth between layers.
      if (event.type === 'temperature-layer:update') {
        const { location, locationsNearby } = createLocationPopupData({
          location: event.location,
          locationsNearby: event.locationsNearby,
          localeCode
        });
        setPopupData({
          location: location,
          locationsNearby,
          temperature: event.temperature.temperature,
          pin: event.pin,
          point: event.point
        });
      }

      if (event.type === 'temperature-layer:start-click') {
        setPopupData(undefined);
        // We are using point here only to rerender the loader. We need a value that is updated every click
        setLoaderData({
          pin: event.pin,
          point: event.point
        });
        setPopup({ showPopup: true, isClosed: false });
      }
    }

    // Because we are aborting the fetch when a zoom is initated, we want to remove the loader
    // if the fetch is not complete so it doesnt show the loading state until the user removes it.
    function handleZoom() {
      if (loaderData != null) {
        setLoaderData(undefined);
      }
    }

    layer.addEventListener(handleEvent);

    map.maplibregl.on('zoom', handleZoom);

    return () => {
      layer.removeEventListener(handleEvent);
      map.maplibregl.off('zoom', handleZoom);
    };
  }, [map, layer, localeCode, popup.isClosed, loaderData]);

  if (layer == null) {
    return null;
  }

  return (
    <>
      {loaderData != null && popup.showPopup === true ? (
        <MapPopup__Loader
          map={map}
          pin={loaderData.pin}
          point={loaderData.point}
          children={true}
          onClose={() => {
            setPopupData(undefined);
            setLoaderData(undefined);
            setPopup({ showPopup: false, isClosed: true });
            layer.cancelClick();
          }}
        ></MapPopup__Loader>
      ) : (
        popupData != null &&
        popup.showPopup === true && (
          <MapPopup
            map={map}
            pin={popupData.pin}
            location={popupData.location}
            locationsNearby={popupData.locationsNearby}
            trackingCategory={'map_temperature'}
            point={popupData.point}
            onClose={() => {
              setPopupData(undefined);
              setLoaderData(undefined);
              setPopup({ showPopup: false, isClosed: true });
              layer.cancelClick();
            }}
          >
            <div className="map-type-temperature__popup-content">
              <Icon size={2} id="icon-temperature" />
              <Temperature value={popupData.temperature} decimal={false} />
            </div>
          </MapPopup>
        )
      )}
    </>
  );
}
