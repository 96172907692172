import { ILightningLayerEvent, LightningLayer } from '@nrk/yr-map';
import { useEffect, useState } from 'react';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IMapDataLegendValues, MapDataLegend } from '../MapDataLegend/MapDataLegend';

export function MapDataLightningLegend({ layer }: { layer: LightningLayer }) {
  const translate = useTranslate();

  const [activeHourIndex, setActiveHourIndex] = useState<number>(layer.getActiveHourIndex());
  const [hours, setHours] = useState<number[]>(layer.getHours());

  useEffect(() => {
    function handleEvent(event: ILightningLayerEvent) {
      if (event.type === 'lightning-layer:active-hour-index') {
        setActiveHourIndex(event.index);
      }

      if (event.type === 'lightning-layer:hours') {
        setHours(event.hours);
      }
    }

    layer.addEventListener(handleEvent);

    return () => {
      layer.removeEventListener(handleEvent);
    };
  }, [layer]);

  const maxLightningAge = hours[activeHourIndex];

  const lightningValues: IMapDataLegendValues[] = [
    {
      color: '#FF7300',
      text: {
        label: translate('map/dataLightningLegend/underOneMinute/label'),
        ariaLabel: translate('map/dataLightningLegend/underOneMinute/ariaLabel')
      }
    },
    {
      color: '#AE5EED',
      text: {
        label: translate('map/dataLightningLegend/minutes/label/short', { minutes: 10 }),
        ariaLabel: translate('map/dataLightningLegend/minutesInterval/ariaLabel', { fromMinute: 1, toMinute: 10 })
      }
    },
    {
      color: '#C795EF',
      text: {
        label: translate('map/dataLightningLegend/minutes/label/short', { minutes: 60 }),
        ariaLabel: translate('map/dataLightningLegend/minutesInterval/ariaLabel', { fromMinute: 10, toMinute: 60 })
      }
    }
  ];

  if (maxLightningAge > 1) {
    lightningValues.push({
      color: '#BCADC7',
      text: {
        label: translate('map/dataLightningLegend/hours/label/short', { hours: 12 }),
        ariaLabel: translate('map/dataLightningLegend/hoursInterval/ariaLabel', { fromHour: 1, toHour: 12 })
      }
    });
  }

  if (maxLightningAge > 12) {
    lightningValues.push({
      color: '#DDDAE0',
      text: {
        label: translate('map/dataLightningLegend/hours/label/short', { hours: 24 }),
        ariaLabel: translate('map/dataLightningLegend/hoursInterval/ariaLabel', { fromHour: 12, toHour: 24 })
      }
    });
  }

  return (
    <>
      <MapDataLegend icondId={'icon-lightning'} values={lightningValues} label={''} ariaLabel={''} />
    </>
  );
}
