import { IApiTileData } from '@nrk/yr-map';
import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IDirectoryWmsResponse } from '../../model/api/directory';
import { IAPIMapFeature } from '../../model/api/mapFeature';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseLightning, parseMapFeature, parseTemperature, parseWind } from './parse';

export function queryDirectoryWms({ localeCode }: { localeCode: LocaleCode }) {
  const queryKey = ['radar', localeCode];

  async function queryFn() {
    return await api<IDirectoryWmsResponse>({
      path: template(settings.api.paths.radar, { localeCode })
    });
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryWindData() {
  const queryKey = ['wind'];

  async function queryFn() {
    const response = await fetch(settings.external.map.windTiles);
    const data = (await response.json()) as IApiTileData;

    return parseWind({ data });
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryMapFeature({ localeCode, locationId }: { localeCode: LocaleCode; locationId?: string }) {
  const queryKey = ['mapFeature', localeCode, locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IAPIMapFeature>({
      path: template(settings.api.paths.mapFeature, { localeCode, locationId })
    });

    return parseMapFeature({ localeCode, data: response });
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryRadarForecastData() {
  const queryKey = ['radarForecast'];

  async function queryFn() {
    const response = await fetch(settings.external.map.radarForecastTiles);
    const data = (await response.json()) as IApiTileData;

    return data;
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryRadarObservationData() {
  const queryKey = ['radarObservation'];

  async function queryFn() {
    const response = await fetch(settings.external.map.radarObservationTiles);
    const data = (await response.json()) as IApiTileData;

    return data;
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryLightningData() {
  const queryKey = ['lightning'];

  async function queryFn() {
    const response = await api<IApiLightningData>({
      path: settings.api.paths.lightning
    });

    return parseLightning({ data: response });
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryTemperatureData() {
  const queryKey = ['temperature'];

  async function queryFn() {
    const response = await fetch(settings.external.map.temperatureTiles);
    const data = (await response.json()) as IApiTileData;

    return parseTemperature({ data });
  }

  return {
    queryKey,
    queryFn
  };
}

export interface IApiLightningData {
  historicalData: string;
}
