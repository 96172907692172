import { useTranslate } from '../../lib/hooks/useTranslate';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { Text } from '../Text/Text';
import './MapWindInformationDialog.scss';
interface IProps {
  onClose: () => void;
}

export function MapWindInformationDialog(props: IProps) {
  const { onClose } = props;
  const translate = useTranslate();

  const scaleValues = [
    { color: '#310046', label: translate('mapPage/wind/legend/beaufort/12') },
    { color: '#4D0A6C', label: translate('mapPage/wind/legend/beaufort/11') },
    { color: '#5B278D', label: translate('mapPage/wind/legend/beaufort/10') },
    { color: '#7043A8', label: translate('mapPage/wind/legend/beaufort/9') },
    { color: '#7B57ED', label: translate('mapPage/wind/legend/beaufort/8') },
    { color: '#4B87EA', label: translate('mapPage/wind/legend/beaufort/7') },
    { color: '#13A8D6', label: translate('mapPage/wind/legend/beaufort/6') },
    { color: '#3CBEBE', label: translate('mapPage/wind/legend/beaufort/5') },
    { color: '#79CCAC', label: translate('mapPage/wind/legend/beaufort/4') },
    { color: '#A7CEA1', label: translate('mapPage/wind/legend/beaufort/0-3') }
  ];

  return (
    <ModalDialog
      testId="map-wind-info-dialog"
      renderOnServer={false}
      onClose={onClose}
      size="small"
      title={translate('mapPage/wind/legend/title')}
    >
      <div className="map-wind-information-dialog" role="presentation">
        <p className="map-wind-information-dialog__lead">{translate('mapPage/wind/legend/lead')}</p>
        <Text size="4" weight="bold" as="p" className="map-wind-information-dialog__scale-heading">
          {translate('mapPage/wind/legend/scaleHeading')}
        </Text>
        <ol className="map-wind-information-dialog__scale-gradient">
          {scaleValues.map(value => (
            <li className="map-wind-information-dialog__scale-gradient-value" key={`${value.color}-${value.label}`}>
              <div
                style={{ backgroundColor: value.color }}
                className="map-wind-information-dialog__scale-gradient-color"
              ></div>
              <p className="map-wind-information-dialog__scale-gradient-color-label">{value.label}</p>
            </li>
          ))}
        </ol>

        <p className="map-wind-information-dialog__body">{translate('mapPage/wind/legend/body')}</p>
      </div>
    </ModalDialog>
  );
}
