import { IWindLayerEvent, WindLayer } from '@nrk/yr-map';
import yrTime from '@nrk/yr-time';
import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { track } from '../../lib/analytics/track';
import { createRelativeTimeLabel, createTimeLabel } from '../../lib/helpers/time';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ITranslateFunction } from '../../model/translate';
import { IPlayerTime, MapPlayer } from '../MapPlayer/MapPlayer';
import './MapWindToolbar.scss';

interface IProps {
  layer: WindLayer;
}

export function MapWindToolbar(props: IProps) {
  const { layer } = props;

  const translate = useTranslate();
  const { currentPage } = useAppState();
  const { embedded } = currentPage.details.query;
  const [currentFrameIndex, setCurrentFrameIndex] = useState<number>(layer.getCurrentFrameIndex());
  const frames = layer.getFrames();
  const [isPlaying, setIsPlaying] = useState(layer.getIsPlaying());
  const [hasTrackedChooseTime, setHasTrackedChooseTime] = useState(false);

  useEffect(() => {
    function handleEvent(event: IWindLayerEvent) {
      if (event.type === 'wind-layer:current-frame') {
        setCurrentFrameIndex(event.index);
      }

      if (event.type === 'wind-layer:is-playing') {
        setIsPlaying(event.value);
      }
    }

    layer.addEventListener(handleEvent);

    return () => {
      layer.removeEventListener(handleEvent);
    };
  }, [layer]);

  // Do not render the toolbar until we have data from Map events
  if (frames == null || currentFrameIndex == null || isPlaying == null) {
    return null;
  }

  // We are using the user's local time to display labels in the wind toolbar
  // Since data from the wind api is in UTC we are adding an offset based on user's local time.
  const clientTimeOffset = yrTime.create().offset();

  const timesWithOffset: string[] = frames.map(frame => {
    return yrTime.create(frame).offset(clientTimeOffset).timeString;
  });

  const times: IPlayerTime[] = createTimes({ timesWithOffset, embedded, translate });

  const startLabel = createTimeLabel({ translate, time: times[0].time, type: 'date-with-time-short' });
  const endLabel = createTimeLabel({ translate, time: times[times.length - 1].time, type: 'date-with-time-short' });

  return (
    <div data-player-type={embedded === true ? 'mini' : 'normal'} className="map-wind-toolbar">
      <MapPlayer
        startLabel={startLabel}
        times={times}
        endLabel={endLabel}
        isPlaying={isPlaying}
        currentTimeIndex={currentFrameIndex}
        shouldRenderBottomLabels={true}
        type="wind"
        playerType={embedded === true ? 'mini' : 'normal'}
        // We only want to change the time showing in the toolbar while scrubbing.
        onChange={frameIndex => {
          layer.pause();
          setCurrentFrameIndex(frameIndex);
        }}
        // We only want to change the current frame in wind layer when the user stop scrubbing.
        // This is because we do not want to download tiles for each frame the user scrubs past.
        onBlur={frameIndex => {
          // Track the first time someone interacts with the player.
          if (hasTrackedChooseTime === false) {
            setHasTrackedChooseTime(true);
            track.event({ category: 'map_wind', action: 'choose_time' });
          }

          layer.setSourceIndex(frameIndex);
          layer.pause();
        }}
        onPlay={() => {
          track.event({ category: 'map_wind', action: 'play' });

          layer.play();
        }}
        onPause={() => {
          track.event({ category: 'map_wind', action: 'pause' });

          layer.pause();
        }}
      />
    </div>
  );
}

function createTimes({
  timesWithOffset,
  embedded,
  translate
}: {
  timesWithOffset: string[];
  embedded: boolean;
  translate: ITranslateFunction;
}) {
  let nextDayTimeDiffIsLower: boolean;

  return timesWithOffset.map((time, index) => {
    const label =
      embedded === true
        ? createTimeLabel({
            translate,
            time,
            type: 'date-with-time-short',
            transform: 'sentence-case'
          })
        : createRelativeTimeLabel({
            translate,
            isFirstRender: false,
            time,
            type: 'relative-date-with-time-long-date-fallback',
            transform: 'sentence-case'
          });

    const currentTime = yrTime.create(timesWithOffset[index]);
    const nextTime: yrTime.YrTime | undefined = yrTime.create(timesWithOffset[index + 1]);
    const isNextDay = nextTime.isAfter(currentTime, 'day');

    let tick: 'short' | 'long' = 'short';

    if (nextDayTimeDiffIsLower) {
      nextDayTimeDiffIsLower = false;
      tick = 'long';
    }

    if (isNextDay) {
      const timeDiffCurr = Math.abs(nextTime.startOf('day').diff(currentTime, 'hour'));
      const timeDiffNext = Math.abs(nextTime.startOf('day').diff(nextTime, 'hour'));

      if (timeDiffNext < timeDiffCurr) {
        nextDayTimeDiffIsLower = true;
      }

      if (timeDiffCurr < timeDiffNext) {
        tick = 'long';
      }
    }

    return {
      label,
      ariaLabel: label,
      time,
      tick: tick
    };
  });
}
