import { IMapWeatherLayerInterval, IWeatherLayerEvent, WeatherLayer } from '@nrk/yr-map';
import yrTime from '@nrk/yr-time';
import { useEffect, useState } from 'react';
import { createTimeIntervalLabel } from '../../lib/helpers/time';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableButton } from '../Clickable/Clickable';
import './MapWeatherToolbar.scss';

interface IProps {
  layer: WeatherLayer;
  standalone: boolean;
}

export function MapWeatherToolbar(props: IProps) {
  const { layer, standalone } = props;

  const translate = useTranslate();
  const [activeIntervalIndex, setActiveIntervalIndex] = useState<number>(layer.getCurrentIndex());
  const [intervals, setIntervals] = useState<IMapWeatherLayerInterval[] | undefined>(layer.getIntervals());

  useEffect(() => {
    function handleEvent(event: IWeatherLayerEvent) {
      if (event.type === 'weather-layer:intervals') {
        setIntervals(event.intervals);
      }

      if (event.type === 'weather-layer:active-interval-index') {
        setActiveIntervalIndex(event.index);
      }
    }
    layer.addEventListener(handleEvent);

    return () => {
      layer.removeEventListener(handleEvent);
    };
  }, [layer]);

  if (intervals == null || activeIntervalIndex == null) {
    // Because safari is having a problem rendering new items into an empty portal on a rerender,
    // we need to render an empty div with the same class name before we get the data.
    // If not we will show an invisble MapWeatherToolbar on safari mobile devices before any action is taken.
    return <div className="map-weather-toolbar__button"></div>;
  }

  const clientTimeOffset = yrTime.create().offset();

  return (
    <div className="map-weather-toolbar">
      {intervals.map((interval, index) => {
        const isActive = index === activeIntervalIndex;

        // Only show 4 buttons
        if (index >= 4) {
          return null;
        }

        return (
          <ClickableButton
            className="map-weather-toolbar__button"
            aria-label={translate('grammar/timeRange', {
              from: standalone
                ? yrTime
                    .create(interval.start)
                    .offset(clientTimeOffset)
                    .format('HH')
                : yrTime.create(interval.start).format('HH'),
              to: standalone
                ? yrTime
                    .create(interval.end)
                    .offset(clientTimeOffset)
                    .format('HH')
                : yrTime.create(interval.end).format('HH')
            })}
            aria-pressed={isActive}
            disabled={isActive}
            key={interval.end}
            analytics={{ category: 'map_weather', action: 'choose_time', value: index }}
            onClick={() => {
              layer.setActiveIntervalIndex(index);
            }}
          >
            {createTimeIntervalLabel({
              type: 'hour',
              from: standalone ? yrTime.create(interval.start).offset(clientTimeOffset).timeString : interval.start,
              to: standalone ? yrTime.create(interval.end).offset(clientTimeOffset).timeString : interval.end,
              translate,
              transform: 'sentence-case'
            })}
          </ClickableButton>
        );
      })}
    </div>
  );
}
