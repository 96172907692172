import { useTranslate } from '../../lib/hooks/useTranslate';
import { CloseButton } from '../CloseButton/CloseButton';
import { Text } from '../Text/Text';
import './MapSnackbar.scss';

interface IProps {
  onClose: () => void;
}

export function MapSnackbar(props: IProps) {
  const { onClose } = props;

  const translate = useTranslate();

  return (
    <div className="map-snackbar">
      <div className="map-snackbar__info">
        <img className="map-snackbar__symbol" alt="" src="/assets/images/animated/map-marker.svg" />
        <Text className="map-snackbar__text" as="p" size="4">
          {translate('searchPage/map/snackbar')}
        </Text>
      </div>
      <div className="map-snackbar__close-button">
        <CloseButton as="button" onClick={onClose} />
      </div>
    </div>
  );
}
