import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useQueryWrapper } from '../query';
import {
  queryDirectoryWms,
  queryMapFeature,
  queryWindData,
  queryRadarForecastData,
  queryRadarObservationData,
  queryLightningData,
  queryTemperatureData
} from './queries';

export function useFetchDirectoryWms() {
  const localeCode = useLocaleCode();

  return useQueryWrapper(queryDirectoryWms({ localeCode }));
}

export function useFetchWindData() {
  return useQueryWrapper(queryWindData());
}

export function useFetchMapFeatures({ locationId }: { locationId?: string }) {
  const localeCode = useLocaleCode();

  return useQueryWrapper({
    ...queryMapFeature({ localeCode, locationId }),

    // The query will not execute until locationId exists
    enabled: locationId != null
  });
}

export function useFetchRadarForecastData() {
  return useQueryWrapper(queryRadarForecastData());
}

export function useFetchRadarObservationData() {
  return useQueryWrapper(queryRadarObservationData());
}

export function useFetchLightningData() {
  return useQueryWrapper({
    ...queryLightningData()
  });
}

export function useFetchTemperatureData() {
  return useQueryWrapper(queryTemperatureData());
}
