import { getCombinedRadarData, IMapStyle, RadarLayer, YrMap } from '@nrk/yr-map';
import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import settings from '../../app/settings';
import { useFetchRadarForecastData, useFetchRadarObservationData } from '../../data/map/hooks';
import { track } from '../../lib/analytics/track';
import { getZoomLevelsForLayer } from '../../lib/helpers/zoomLevels';
import { useCurrentLocationId } from '../../lib/hooks';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { MAP_PORTAL_INFO_BUTTON_ID, MAP_PORTAL_LEGEND_ID, MAP_PORTAL_TYPE_TOOLBAR_ID } from '../Map/Map';
import { MapDataRadarLegend } from '../MapDataRadarLegend/MapDataRadarLegend';
import { MapIconButton } from '../MapIconButton/MapIconButton';
import { MapRadarInformationDialog } from '../MapRadarInformationDialog/MapRadarInformationDialog';
import { MapRadarToolbar } from '../MapRadarToolbar/MapRadarToolbar';
import { PortalWrapper } from '../PortalWrapper/PortalWrapper';
import { MapTypeRadar__Popup } from './MapTypeRadar__Popup';

const RADAR_LAYER_ID = 'radar';

// These colors are from https://nrknyemedier.atlassian.net/browse/YR-4214
// Ideally we would get these colours from the API,
// see https://nrknyemedier.atlassian.net/browse/YR-4248
export const RADAR_DATA_COLORS = ['#7A0087', '#0055FF', '#0080FF', '#00AAFF', '#5ED7FF', '#91E4FF'];

interface IProps {
  map: YrMap;
  style: IMapStyle;
  setAriaLabel: (label: string) => void;
}

export function MapTypeRadar(props: IProps) {
  const { map, setAriaLabel, style } = props;
  const { currentPage } = useAppState();
  const { zoom, embedded } = currentPage.details.query;

  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const locationId = useCurrentLocationId();

  const [showInformation, setShowInformation] = useState(false);
  const [layer, setLayer] = useState<RadarLayer>();
  const [hasTrackedPan, setHasTrackedPan] = useState(false);
  const [initialIndexIsNow, setInitialIndexIsNow] = useState(false);

  const { data: radarForecastData } = useFetchRadarForecastData();

  const { data: radarObservationData } = useFetchRadarObservationData();

  const zoomLevels = getZoomLevelsForLayer({ embedded, layer: 'radar', zoom, locationId });

  useEffect(() => {
    if (layer == null) {
      return;
    }
    if (map.getLayer(RADAR_LAYER_ID) != null) {
      map.showLayer(RADAR_LAYER_ID);
      return;
    }

    map.addLayer({
      layer,
      bounds: locationId == null ? settings.map.bounds.radar : undefined,
      renderBeforeId: style.renderBeforeId,
      zoomLevels,
      pin: 'dynamic-secondary-with-static-primary',
      styleId: style.id,
      resetWhenShown: true
    });
    map.showLayer(RADAR_LAYER_ID);
    setAriaLabel(translate('mapPage/types/radar/name'));
  }, [map, layer, locationId, style, setAriaLabel, translate]);

  useEffect(() => {
    if (radarForecastData == null || radarObservationData == null) {
      return;
    }
    const radarData = getCombinedRadarData({ observations: radarObservationData, forecast: radarForecastData });
    const mapLayer = map.getLayer(RADAR_LAYER_ID);

    setInitialIndexIsNow(radarData.initialIndexIsNow);

    if (mapLayer != null) {
      setLayer(mapLayer.layer as RadarLayer);
    } else {
      setLayer(
        new RadarLayer({
          id: RADAR_LAYER_ID,
          map,
          radarData
        })
      );
    }
  }, [map, radarForecastData, radarObservationData, layer]);

  useEffect(() => {
    if (layer == null) {
      return;
    }

    // Track the first time someone drags in the radar layer.
    function handleDragStart() {
      if (hasTrackedPan === false) {
        setHasTrackedPan(true);
        track.event({ category: 'map_radar', action: 'pan' });
      }
    }

    map.maplibregl.on('dragstart', handleDragStart);

    return () => {
      map.maplibregl.off('dragstart', handleDragStart);
    };
  }, [map, layer, hasTrackedPan, localeCode]);

  function onOpenInformation() {
    track.event({ category: 'map_radar', action: 'open_info' });

    setShowInformation(true);
  }

  function onCloseInformation() {
    setShowInformation(false);
  }

  if (layer == null) {
    return null;
  }

  return (
    <div className="map-type-radar">
      <PortalWrapper id={MAP_PORTAL_TYPE_TOOLBAR_ID}>
        <MapRadarToolbar layer={layer} initialIndexIsNow={initialIndexIsNow} />
      </PortalWrapper>

      <PortalWrapper id={MAP_PORTAL_LEGEND_ID}>
        <MapDataRadarLegend />
      </PortalWrapper>

      <MapTypeRadar__Popup layer={layer} map={map} />

      <PortalWrapper id={MAP_PORTAL_INFO_BUTTON_ID}>
        <MapIconButton
          data-testid="map-info-button"
          iconId="icon-i"
          ariaLabel={translate('infoButton/ariaLabel')}
          onClick={onOpenInformation}
        />
      </PortalWrapper>

      {showInformation && <MapRadarInformationDialog onClose={onCloseInformation} />}
    </div>
  );
}
