import { createPortal } from 'react-dom';
import { useAppState } from '../../app/contexts/AppStateContext';

interface IProps {
  id: string;
  children: React.ReactNode;
}

export function PortalWrapper(props: IProps) {
  const { id, children } = props;
  const { isFirstRender, currentPage } = useAppState();
  const { embedded } = currentPage.details.query;

  if (isFirstRender === true) {
    return null;
  }

  const targetElement = document.querySelector(`#${id}`);

  // Because nrk.no are embedding our maps we dont want to throw an error
  // if we have removed the div-element from map.tsx.
  if (targetElement == null && embedded === true) {
    return <></>;
  }

  if (targetElement == null) {
    throw new Error(`Can't find element with id ${id}, make sure the element exists in the DOM.`);
  }

  return createPortal(children, targetElement);
}
