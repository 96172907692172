import { WindLayer } from '@nrk/yr-map';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { MapDataLegend } from '../MapDataLegend/MapDataLegend';
import { IColorValues, processLegendData } from './helpers/data';

interface IProps {
  windLayer: WindLayer;
}

export function MapDataWindLegend(props: IProps) {
  const { windLayer } = props;
  const translate = useTranslate();

  const colorValues: IColorValues[] = windLayer.getHeatMapColors();

  const processedData = processLegendData(colorValues, translate);

  return (
    <>
      <MapDataLegend
        icondId={'icon-wind'}
        label={translate('units/metersPerSecond/short')}
        ariaLabel={translate('units/metersPerSecond/long')}
        values={processedData}
      />
    </>
  );
}
