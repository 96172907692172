import { createPageUrl } from '../../../app/redirects';
import { parseLocation } from '../../../data/locations/parse';
import { IAPILocation } from '../../../model/api/location';
import { LocaleCode } from '../../../model/locale';
import { ILocation } from '../../../model/location';

interface IApiLocationsNearby extends IAPILocation {
  distanceInMeters: number;
}
export interface ILocationsNearby extends ILocation {
  distanceInMeters: number;
  url: string;
}

export function createLocationPopupData({
  location,
  locationsNearby,
  localeCode
}: {
  location?: IAPILocation;
  locationsNearby: IApiLocationsNearby[];
  localeCode: LocaleCode;
}) {
  const parsedLocation = location != null ? parseLocation(location) : undefined;
  const parsedLocationsNearby =
    locationsNearby.length > 0
      ? locationsNearby.map(location => {
          const parsedLocation = parseLocation(location);
          const url =
            parsedLocation.hasCoast && location.isInOcean
              ? createPageUrl({
                  localeCode,
                  pageId: 'coast',
                  subpageId: 'forecast',
                  urlPath: location.id
                })
              : createPageUrl({
                  localeCode,
                  pageId: 'forecast',
                  subpageId: 'daily-table',
                  urlPath: location.id
                });

          return { ...parsedLocation, distanceInMeters: location.distanceInMeters, url };
        })
      : [];

  return { location: parsedLocation, locationsNearby: parsedLocationsNearby };
}
