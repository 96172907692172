import { IWeatherLayerDescriptionValues } from '@nrk/yr-map';
import { ITranslateFunction } from '../../../model/translate';

export function getWeatherMapDescription({
  descriptionValues,
  translate
}: {
  descriptionValues: IWeatherLayerDescriptionValues[];
  translate: ITranslateFunction;
}) {
  const unitLabels = {
    distance: translate('units/kilometer/short'),
    temperature: translate('units/celsius/short'),
    wind: translate('units/metersPerSecond/short')
  };

  const weatherMapDescription = descriptionValues.map(location => {
    const { name, distance, direction, temperature, symbol, wind } = location;

    const locationDescriptions = [];

    locationDescriptions.push(
      translate('weatherMap/a11y/locationLabel', {
        name,
        unit: unitLabels.distance,
        distance,
        direction: translate(`direction/${direction}/long`)
      })
    );

    locationDescriptions.push(
      translate('weatherMap/a11y/temperatureLabel', { temperature, unit: unitLabels.temperature })
    );

    if (symbol != null) {
      locationDescriptions.push(translate(`symbols/s${symbol.slice(0, 2)}`));
    }

    const windTypeLabel = translate(`wind/type/${wind.type}`);
    locationDescriptions.push(
      translate('weatherMap/a11y/windLabel', {
        speed: wind.speed,
        unit: unitLabels.wind,
        type: windTypeLabel,
        direction: translate(`direction/${wind.direction}/long`)
      })
    );

    return locationDescriptions.join(', ');
  });

  return `${weatherMapDescription.join('. ')}.`;
}
