// @ts-ignore
import numberUtils from '@nrk/yr-number-utils';
import { numberToString } from '../format';
import { ITranslateFunction } from '../../model/translate';

export function createDistanceFromLabel({
  meters,
  from,
  translate
}: {
  meters: number;
  from: string;
  translate: ITranslateFunction;
}) {
  const km = numberUtils.round(meters / 1000, 1);
  const kmLabel = numberToString(km, translate);

  return translate('grammar/kmDistanceFrom', { kilometers: kmLabel, locationName: from });
}
