import { YrMap } from '@nrk/yr-map';
import { IPoint } from '@nrk/yr-map/dist/types/types';
import { Fragment } from 'react';
import { createPageUrl } from '../../app/redirects';
import { track } from '../../lib/analytics/track';
import { createDistanceFromLabel } from '../../lib/helpers/distance';
import { useCurrentLocationId } from '../../lib/hooks';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ILocation } from '../../model/location';
import { Button } from '../Button/Button';
import { CloseButton } from '../CloseButton/CloseButton';
import { Heading } from '../Heading/Heading';
import { LocationRegion } from '../LocationRegion/LocationRegion';
import { Text } from '../Text/Text';
import { ILocationsNearby } from './helpers/data';
import './MapPopup.scss';
import { MapPopup__Container } from './MapPopup__Container';

interface IProps {
  map: YrMap;
  pin: 'primary' | 'secondary';
  children?: React.ReactNode;
  onClose: () => void;
  location?: ILocation;
  locationsNearby: ILocationsNearby[];
  trackingCategory: 'map_radar' | 'map_wind' | 'map_weather' | 'map_lightning' | 'map_temperature';
  showLocationList?: boolean;
  point: IPoint;
}

export function MapPopup(props: IProps) {
  const {
    map,
    pin,
    children,
    onClose,
    location,
    locationsNearby,
    trackingCategory,
    showLocationList = false,
    point
  } = props;

  const localeCode = useLocaleCode();
  const translate = useTranslate();
  const currentLocationId = useCurrentLocationId();

  if (location == null) {
    return null;
  }

  const url =
    location.hasCoast && location.isInOcean
      ? createPageUrl({
          localeCode,
          pageId: 'coast',
          subpageId: 'forecast',
          locationId: location.id
        })
      : createPageUrl({
          localeCode,
          pageId: 'forecast',
          subpageId: 'daily-table',
          locationId: location.id
        });

  return (
    <MapPopup__Container map={map} pin={pin} point={point}>
      <Heading level="h3" size="4">
        {location.name}
      </Heading>
      <Text as="p" size="5" className="map-popup__subtitle">
        <LocationRegion location={location} />
      </Text>
      {children}
      {/* Avoid linking back to the location the user is already on */}
      {location.id !== currentLocationId && (
        <Button
          className="map-popup__navigation-button"
          href={url}
          as="link"
          onClick={() => {
            track.event({ category: trackingCategory, action: 'navigate_to_main_location_in_popup' });
          }}
        >
          {location.hasCoast && location.isInOcean
            ? translate('searchPage/map/goToCoastalForecast')
            : translate('searchPage/map/goToWeatherForecast')}
        </Button>
      )}
      {locationsNearby.length > 0 && showLocationList && (
        <div className="map-popup__location-list">
          <Text className="map-popup__location-list-header" size="4" as="p">
            {translate('searchPage/map/placesNearby')}
          </Text>
          <span className="map-popup__location-list-distance-text">
            {locationsNearby.map((location, index) => {
              const separator = index < locationsNearby.length - 1 ? ', ' : '';

              return (
                <Fragment key={location.id}>
                  <Text
                    size="4"
                    as="a"
                    href={location.url}
                    isLink={true}
                    onClick={() =>
                      track.event({ category: trackingCategory, action: 'navigate_to_secondary_location_in_popup' })
                    }
                  >
                    {location.name}
                  </Text>
                  <Text size="4">
                    {' ('}
                    {createDistanceFromLabel({
                      meters: location.distanceInMeters,
                      from: translate('searchPage/map/point'),
                      translate: translate
                    })}
                    {')'}
                    {separator}
                  </Text>
                </Fragment>
              );
            })}
          </span>
        </div>
      )}
      <div className="map-popup__close-button">
        <CloseButton as="button" buttonSize="small" onClick={onClose} />
      </div>
    </MapPopup__Container>
  );
}
