import { Coordinate, CoordinateWithOptionalY } from '../../model/coordinate';
import { IGraphLabel, IGraphYAxisTicks, IGraphDimension } from '../../model/graph';
import { STATISTICS_GRAPH_Y_AXIS_MIN_ROWS } from './graphConstants';

interface IMinMax {
  min: number;
  max: number;
}

interface ISegment {
  type: 'coordinates' | 'gap';
  coordinates: Coordinate[];
}

export function pointsToPolylinePoints(points: number[][]) {
  return points.map(tuple => tuple.join(',')).join(' ');
}

export function getGraphYCoordinate(value: number, minMax: { min: number; max: number }, graphHeight: number) {
  return ((value - minMax.min) / (minMax.max - minMax.min)) * graphHeight;
}

export function rescale({
  value,
  from,
  to
}: {
  value: number;
  from: { min: number; max: number };
  to: { min: number; max: number };
}) {
  return ((value - from.min) * (to.max - to.min)) / (from.max - from.min) + to.min;
}

export function calcHorizontalGridLines(
  minMax: IMinMax,
  allowedStepsLengthArray: number[],
  maxIterations: number,
  onlyPositive?: boolean
) {
  const min = minMax.min;
  const max = minMax.max;
  const range = max - min;

  const initialSteps = (divider: number) => {
    if (Math.ceil(range) === 0) {
      return [0, 1, 2, 3];
    }

    const stride = Math.ceil(range / divider);
    const lowest = Math.floor(min / stride) * stride;
    const highest = Math.ceil(max / stride) * stride;
    const steps = [];

    for (let y = lowest; y <= highest; y += stride) {
      steps.push(y);
    }

    if (stride === 1) {
      if (onlyPositive) {
        steps.push(Math.ceil(max + stride));
      } else {
        steps.push(Math.floor(min - stride));
      }
    }
    return steps;
  };

  let i = Math.max(...allowedStepsLengthArray);
  let iteration = 1;
  let result = initialSteps(i);
  let resultLen = result.length;

  while (allowedStepsLengthArray.indexOf(resultLen) === -1) {
    i -= 1;
    iteration += 1;
    result = initialSteps(i);
    resultLen = result.length;
    // too many iterations
    if (iteration > maxIterations) {
      return [];
    }
  }

  return result;
}

export function convertValuesToSegments(intervals: Array<{ value: number | undefined; time: string }>, color: string) {
  const segments: Array<{ values: Array<{ value: number; time: string }>; color: string }> = [];
  let segment: Array<{ value: number; time: string }> = [];

  intervals.forEach((interval, index) => {
    if (typeof interval.value === 'number') {
      segment.push({ value: interval.value, time: interval.time });

      if (intervals.length === index + 1) {
        segments.push({ values: segment, color });
      }
    } else {
      if (segment.length > 0) {
        segments.push({ values: segment, color });
        segment = [];
      }
    }
  });

  return segments;
}

export function getLastIntervalWithValue(
  intervals: ReadonlyArray<{ value?: number; time: string; direction?: number }>
) {
  return [...intervals].reverse().find(interval => interval.value !== undefined);
}

export function splitCoordinates(coordinates: CoordinateWithOptionalY[]) {
  const segments: ISegment[] = [];

  if (coordinates.length === 0) {
    return segments;
  }

  let currentSegment: ISegment = {
    type: coordinates[0][1] == null ? 'gap' : 'coordinates',
    coordinates: []
  };

  segments.push(currentSegment);

  for (const coordinate of coordinates) {
    const coordinateType = coordinate[1] == null ? 'gap' : 'coordinates';

    if (currentSegment.type !== coordinateType) {
      currentSegment = {
        type: coordinateType,
        coordinates: []
      };

      segments.push(currentSegment);
    }

    currentSegment.coordinates.push([coordinate[0], coordinate[1] == null ? 0 : coordinate[1]]);
  }

  if (segments.length === 1) {
    return segments[0].type === 'coordinates' ? segments : [];
  }

  // Adjust gaps so they touch the segment before or after if any
  for (let i = 0; i < segments.length; i++) {
    const previousSegment = segments[i - 1];
    const currentSegment = segments[i];
    const nextSegment = segments[i + 1];

    if (currentSegment.type === 'coordinates') {
      continue;
    }

    let firstCoordinate: Coordinate = [currentSegment.coordinates[0][0], currentSegment.coordinates[0][1]];
    let lastCoordinate: Coordinate = [
      currentSegment.coordinates[currentSegment.coordinates.length - 1][0],
      currentSegment.coordinates[currentSegment.coordinates.length - 1][1]
    ];

    if (previousSegment) {
      const previousCoordinate = previousSegment.coordinates[previousSegment.coordinates.length - 1];
      firstCoordinate = [previousCoordinate[0], previousCoordinate[1]];

      if (!nextSegment) {
        lastCoordinate[1] = firstCoordinate[1];
      }
    }

    if (nextSegment) {
      const nextCoordinate = nextSegment.coordinates[0];
      lastCoordinate = [nextCoordinate[0], nextCoordinate[1]];

      if (!previousSegment) {
        firstCoordinate[1] = lastCoordinate[1];
      }
    }

    currentSegment.coordinates = [firstCoordinate, lastCoordinate];
  }

  return segments;
}

export function calculateGraphDimensions({
  stride,
  graphShouldGoBelowZero = false,
  min,
  max
}: {
  stride: number;
  graphShouldGoBelowZero?: boolean;
  min?: number;
  max?: number;
}): IGraphDimension {
  const minimumHeight = STATISTICS_GRAPH_Y_AXIS_MIN_ROWS * stride;

  let graphMin = min != null ? min : 0;
  let graphMax = max != null ? max : minimumHeight;

  // If we were given a min value we may want to adjust the graph min
  // to prevent the value from touching a y axis tick.
  if (min != null) {
    // Decrease graph min by one row stride if it touches a y axis tick when rounded down
    if (Math.floor(graphMin) % stride === 0) {
      graphMin -= stride;
    }

    if (graphShouldGoBelowZero === false && graphMin < 0) {
      graphMin = 0;
    }

    // Adjust the graph min so it equals the closest y axis tick below
    graphMin = Math.floor(graphMin / stride) * stride;
  }

  // If we were given a max value we may want to adjust the graph max
  // to prevent the value from touching a y axis tick.
  if (max != null) {
    // Increase graph max by one row stride if it touches a y axis tick when rounded up
    if (Math.ceil(graphMax) % stride === 0) {
      graphMax += stride;
    }

    // Adjust the graph max so it equals the closest y axis tick above
    graphMax = Math.ceil(graphMax / stride) * stride;
  }

  // If the resulting graph is too short adjust the max to make the graph as tall as the minimum height
  if (graphMax - graphMin < minimumHeight) {
    graphMax = graphMin + minimumHeight;
  }

  const graphHeight = Math.abs(graphMax - graphMin);
  const rows = graphHeight / stride;

  return { graphMin, graphMax, graphHeight, rows, stride };
}

export function createYAxisTicks({
  max,
  rows,
  stride,
  getLabel
}: {
  max: number;
  rows: number;
  stride: number;
  getLabel?: (value: number) => IGraphLabel;
}): IGraphYAxisTicks {
  const yAxis: IGraphYAxisTicks = { type: 'ticks', ticks: [] };
  let value = max;
  for (let i = 0; i <= rows; i++) {
    yAxis.ticks.push({
      label: getLabel ? getLabel(value) : { type: 'number', value },
      normalizedY: i / rows
    });

    value -= stride;
  }

  return yAxis;
}

export function calculateGraphMetricsForGraphWithFixedNumberOfRows({
  minValue,
  maxValue,
  graphRows,
  // Assuming a minimum of 3 rows, the max default stride of 100 should be enough
  // for the graph to suppport a wind speed of 300 mps and a temperature range of 300°C
  // which is much greater than the highest wind speed recorded on earth
  // and also much greater than the range between the lowest and highest temperatures
  // ever recorded on earth.
  strides = [1, 2, 3, 4, 6, 8, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  minimumRowsAbove = 0,
  zeroBasedGraph,
  showYAxisLabelsOn
}: {
  minValue: number;
  maxValue: number;
  graphRows: number;
  strides?: number[];
  /**
   * How many rows should be left empty at the top of the graph above its content?
   * Necessary for the temperature curve in the meteogram where we show weather
   * symbols above the temperature curve that should not be placed outside the graph.
   */
  minimumRowsAbove?: number;
  /**
   * Zero based graphs show zero at the bottom of the graph.
   * Precipitation graphs and wind, for example, are naturally zero based,
   * while temperature graphs which can have values above and below zero
   * are not.
   */
  zeroBasedGraph: boolean;
  /**
   * Does the graph intend to show y axis labels on all ticks or only on even ticks?
   * We need to know this for non zero based graphs where we want to calculate the
   * min graph value to ensure the 0 value tick has a visible y axis label.
   */
  showYAxisLabelsOn: 'even-ticks' | 'all-ticks';
}) {
  if (zeroBasedGraph && minValue < 0) {
    throw new Error("minValue can't be below 0 when graphStartsAtZero is true");
  }

  // Round min down and max up to the closest whole number
  minValue = zeroBasedGraph ? 0 : findClosestValueBelowDivisibleByDivisor({ value: minValue, divisor: 1 });
  maxValue = findClosestValueAboveDivisibleByDivisor({ value: maxValue, divisor: 1 });

  // If the graph does not start at zero we want to calculate the min and max graph values
  // to ensure that the content of the graph is centered vertically within the graph.
  const centerContentVertically = zeroBasedGraph === false;

  const includesZeroValue = zeroBasedGraph ? true : minValue <= 0 && maxValue >= 0;

  for (const stride of strides) {
    let graphMinValue = findClosestValueBelowDivisibleByDivisor({ value: minValue, divisor: stride });

    // We always want to show a y axis label for the 0 value tick,
    // so if we only show y axis labels on even ticks, the graph content is centered vertically,
    // and the graph includes a zero value, we need to adjust graphMinValue to ensure that
    // the 0 value tick falls on an even tick.
    if (centerContentVertically && includesZeroValue && showYAxisLabelsOn === 'even-ticks') {
      // We know/assume the bottom tick in the graph for graphMinValue has a y axis label
      // so to ensure that the 0 value tick gets a visible y axis label we must potentially
      // adjust graphMinValue ensure there is an even number of ticks between graphMinValue and 0.
      const isTicksBetweenGraphMinValueAndZeroEvent = Math.abs(graphMinValue) % 2 === 0;
      if (isTicksBetweenGraphMinValueAndZeroEvent === false) {
        graphMinValue -= 1;
      }
    }

    let graphMaxValue = graphMinValue + graphRows * stride;

    // If the max value of a graph with the current stride minus the minimum
    // number of rows we want above the graph content is less than the max value
    // we need to pick another stride.
    if (graphMaxValue - minimumRowsAbove * stride < maxValue) {
      continue;
    }

    const closestTickAboveMaxValue = findClosestValueAboveDivisibleByDivisor({ value: maxValue, divisor: stride });
    const closestTickBelowMinValue = findClosestValueBelowDivisibleByDivisor({ value: minValue, divisor: stride });

    let emptyRowsAboveClosestTickAboveMaxValue = (graphMaxValue - closestTickAboveMaxValue) / stride;
    let emptyRowsBelowClosestTickBelowMinValue = (closestTickBelowMinValue - graphMinValue) / stride;

    // If we need to center the content vertically we move half the empty rows
    // from above the content to below with a bias towards more empty rows above.
    if (centerContentVertically) {
      const totalEmptyRows = emptyRowsAboveClosestTickAboveMaxValue + emptyRowsBelowClosestTickBelowMinValue;

      emptyRowsAboveClosestTickAboveMaxValue = Math.max(Math.ceil(totalEmptyRows / 2), minimumRowsAbove);
      emptyRowsBelowClosestTickBelowMinValue = totalEmptyRows - emptyRowsAboveClosestTickAboveMaxValue;

      // To continue ensuring we show a y axis label for the 0 value tick
      // when we only show y axis labels on even ticks and the graph includes a zero value
      // we may need to move an empty row from above the graph content to below.
      if (includesZeroValue && showYAxisLabelsOn === 'even-ticks') {
        const potentialNewGraphMinValue = (closestTickBelowMinValue - emptyRowsBelowClosestTickBelowMinValue) * stride;

        // If the number of ticks between the potential new graph min value and 0 is not even
        // we move an empty row from above the graph content to below.
        const isTicksBetweenPotentialNewGraphMinValueAndZeroEvent = Math.abs(potentialNewGraphMinValue) % 2 === 0;
        if (isTicksBetweenPotentialNewGraphMinValueAndZeroEvent === false) {
          emptyRowsAboveClosestTickAboveMaxValue += 1;
          emptyRowsBelowClosestTickBelowMinValue -= 1;
        }
      }

      // Adjust the min and max graph values now that we potentially have moved empty rows around
      graphMinValue = closestTickBelowMinValue - emptyRowsBelowClosestTickBelowMinValue * stride;
      graphMaxValue = graphMinValue + graphRows * stride;
    }

    const graphValueHeight = graphMaxValue - graphMinValue;

    // We calculate the zero tick index and normalized zero y even if the
    // graph does not contain a zero value just to make it easier to work
    // with the data returned by this helper.
    const zeroTickIndex = (0 - graphMinValue) / stride;
    const normalizedZeroY = zeroTickIndex / graphRows;

    return {
      stride,
      graphMinValue,
      graphMaxValue,
      graphValueHeight,
      graphRows,
      contentRows: (closestTickAboveMaxValue - closestTickBelowMinValue) / stride,
      emptyRowsAbove: emptyRowsAboveClosestTickAboveMaxValue,
      emptyRowsBelow: emptyRowsBelowClosestTickBelowMinValue,
      zeroTickIndex,
      normalizedZeroY
    };
  }

  throw new Error('Unable to find a suitable stride to calculate metrics for graph');
}

export function findClosestValueAboveDivisibleByDivisor({ value, divisor }: { value: number; divisor: number }) {
  value = Math.ceil(value);

  if (value % divisor === 0) {
    return value;
  }

  if (value > 0) {
    return value + divisor - (value % divisor);
  }

  if (value < 0) {
    return value - (value % divisor);
  }

  // Value is 0
  return value;
}

export function findClosestValueBelowDivisibleByDivisor({ value, divisor }: { value: number; divisor: number }) {
  value = Math.floor(value);

  if (value % divisor === 0) {
    return value;
  }

  if (value > 0) {
    return value - (value % divisor);
  }

  if (value < 0) {
    return value - divisor - (value % divisor);
  }

  // Value is 0
  return value;
}

export function calculateNormalizedYCoordinate({
  graphMinValue,
  graphMaxValue,
  value
}: {
  graphMaxValue: number;
  graphMinValue: number;
  value: number;
}) {
  const graphValueHeight = graphMaxValue - graphMinValue;

  return (value - graphMinValue) / graphValueHeight;
}

// When using SVG we need to flip the coordinates vertically because our coordinates have 0,0
// at the bottom left while SVG has 0,0 as the top left.
export function flipCoordinatesVertically(coordinates: CoordinateWithOptionalY[]) {
  return coordinates.map(coordinate => {
    const [x, y] = coordinate;

    if (y == null) {
      return coordinate;
    }

    return [x, 1 - y];
  });
}
