import { flip, limitShift, offset, shift, useFloating } from '@floating-ui/react-dom';
import { YrMap } from '@nrk/yr-map';
import { IPoint } from '@nrk/yr-map/dist/types/types';
import { useEffect, useLayoutEffect, useRef } from 'react';
import './MapPopup.scss';

interface IProps {
  map: YrMap;
  pin?: 'primary' | 'secondary';
  children?: React.ReactNode;
  point?: IPoint;
  layer?: 'wind' | 'weather' | 'radar' | 'lightning';
}

export function MapPopup__Container(props: IProps) {
  const { map, pin, children, point, layer } = props;

  const mapRect = map.maplibregl.getCanvas().getBoundingClientRect();
  const markerRef = useRef(pin === 'primary' ? map.getPrimaryPin() : map.getSecondaryPin());

  const { x, y, reference, floating, strategy, update } = useFloating({
    placement: 'top',
    strategy: 'absolute',
    middleware: [
      // Offset the popup vertically from the pin
      offset({ mainAxis: 20 }),

      // Flip the marker when it won't fit above the pin.
      // We need to account for the page header so the popup will appear below
      // the pin instead of being rendered on top of the page header.
      flip({ padding: { top: mapRect.top } }),

      // If the pin is close to or partially outside the left or right edges of the viewport
      // we want to slide the popup a little so it's still fully visible.
      // We limit how much we shift so if the user pans the map and moves the pin completely
      // out of the viewport the popup won't be shifted into view.
      shift({
        limiter: limitShift({
          offset: ({ rects }) => rects.reference.width
        })
      })
    ]
  });

  // Reposition the popup whenever the user pans or zooms the map
  useEffect(() => {
    map.maplibregl.on('move', update);

    return () => {
      map.maplibregl.off('move', update);
    };
  }, [map, update]);

  // Return the pin's current bounding client rect to Floating UI
  useLayoutEffect(() => {
    // Update the marker ref so the getBoundingClientRect() helper will use the most
    // recent marker instead of the old locally scoped version.
    markerRef.current = pin === 'primary' ? map.getPrimaryPin() : map.getSecondaryPin();

    reference({
      getBoundingClientRect() {
        return markerRef.current.getElement().getBoundingClientRect();
      }
    });
  }, [reference, map, pin, point]);

  return (
    <div
      ref={floating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
        width: 'max-content'
      }}
    >
      <div className="map-popup" data-layer={layer}>
        {children}
      </div>
    </div>
  );
}
