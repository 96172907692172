import { TemperatureLayer } from '@nrk/yr-map';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { Text } from '../Text/Text';
import './MapTemperatureInformationDialog.scss';
interface IProps {
  layer: TemperatureLayer;
  onClose: () => void;
}

export function MapTemperatureInformationDialog(props: IProps) {
  const { layer, onClose } = props;
  const translate = useTranslate();

  const colorValues = layer.getHeatMapColors();

  const scaleValues = colorValues
    .map(colorValue => {
      return { color: colorValue.color, label: translate(`mapPage/temperature/legend/${colorValue.temperature}`) };
    })
    .reverse();

  return (
    <ModalDialog
      testId="map-temperature-info-dialog"
      renderOnServer={false}
      onClose={onClose}
      size="small"
      title={translate('mapPage/temperature/legend/title')}
    >
      <div className="map-temperature-information-dialog" role="presentation">
        <p className="map-temperature-information-dialog__lead">{translate('mapPage/temperature/legend/lead')}</p>
        <Text size="4" weight="bold" as="p" className="map-temperature-information-dialog__scale-heading">
          {translate('mapPage/temperature/legend/scaleHeading')}
        </Text>
        <ol className="map-temperature-information-dialog__scale-gradient">
          {scaleValues.map(value => (
            <li
              className="map-temperature-information-dialog__scale-gradient-value"
              key={`${value.color}-${value.label}`}
            >
              <div
                style={{ backgroundColor: value.color }}
                className="map-temperature-information-dialog__scale-gradient-color"
              ></div>
              <p className="map-temperature-information-dialog__scale-gradient-color-label">{value.label}</p>
            </li>
          ))}
        </ol>

        <p className="map-temperature-information-dialog__body">{translate('mapPage/temperature/legend/body')}</p>
      </div>
    </ModalDialog>
  );
}
