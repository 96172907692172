import { useTranslate } from '../../lib/hooks/useTranslate';
import { RADAR_DATA_COLORS } from '../MapTypeRadar/MapTypeRadar';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { Text } from '../Text/Text';
import './MapRadarInformationDialog.scss';
interface IProps {
  onClose: () => void;
}

export function MapRadarInformationDialog(props: IProps) {
  const { onClose } = props;

  const translate = useTranslate();

  return (
    <ModalDialog
      testId="map-radar-info-dialog"
      renderOnServer={false}
      onClose={onClose}
      size="small"
      title={translate('mapPrecipitationInformationDialog/informationLabel')}
    >
      <div className="map-radar-information-dialog" role="presentation">
        <p className="map-radar-information-dialog__lead">{translate('mapPage/legend/lead')}</p>

        <Text size="4" weight="bold" as="p" className="map-radar-information-dialog__scale-heading">
          {translate('mapPage/legend/scaleHeading')}
        </Text>

        <div className="map-radar-information-dialog__scale">
          <div className="map-radar-information-dialog__scale-gradient">
            {RADAR_DATA_COLORS.map(color => (
              <div
                key={color}
                className="map-radar-information-dialog__scale-gradient-color"
                style={{ backgroundColor: color }}
              />
            ))}
          </div>

          <ol className="map-radar-information-dialog__scale-list">
            <li className="map-radar-information-dialog__scale-item map-radar-information-dialog__scale-item--extreme">
              {translate('mapPage/legend/scale/extreme')}
            </li>
            <li className="map-radar-information-dialog__scale-item map-radar-information-dialog__scale-item--heavy">
              {translate('mapPage/legend/scale/heavy')}
            </li>
            <li className="map-radar-information-dialog__scale-item map-radar-information-dialog__scale-item--light">
              {translate('mapPage/legend/scale/light')}
            </li>
          </ol>
        </div>

        <p className="map-radar-information-dialog__body">{translate('mapPage/legend/body')}</p>
      </div>
    </ModalDialog>
  );
}
