import { TemperatureLayer } from '@nrk/yr-map';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { MapDataLegend } from '../MapDataLegend/MapDataLegend';
import { IColorValues, processLegendData } from './helpers/data';

interface IProps {
  temperatureLayer: TemperatureLayer;
}

export function MapDataTemperatureLegend(props: IProps) {
  const { temperatureLayer } = props;
  const translate = useTranslate();

  const colorValues: IColorValues[] = temperatureLayer.getHeatMapColors();

  const processedData = processLegendData(colorValues, translate);

  return (
    <>
      <MapDataLegend
        icondId={'icon-temperature'}
        label={translate('units/celsius/short')}
        ariaLabel={translate('units/celsius/long')}
        values={processedData}
      />
    </>
  );
}
