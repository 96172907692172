import { ITranslateFunction } from '../../../model/translate';
import { IMapDataLegendValues } from '../../MapDataLegend/MapDataLegend';

export interface IColorValues {
  temperature: number;
  color: string;
}

export function processLegendData(colorValues: IColorValues[], translate: ITranslateFunction) {
  const processedData: IMapDataLegendValues[] = [];

  for (let i = colorValues.length - 1; i >= 0; i--) {
    //Push the last and highest value with its color at the start of the array, with more-then sign in label
    if (i === colorValues.length - 1) {
      processedData.push({
        color: colorValues[i].color,
        text: {
          label: `>${colorValues[i].temperature}`,
          ariaLabel: translate('map/dataTemperatureLegend/temperatureEndLong', {
            temperature: colorValues[i].temperature
          })
        }
      });
      continue;
    }
    //Push the first color that belongs to the lowest value and the second to first value with a less-then sign in label
    if (i === 0) {
      processedData.push({
        color: colorValues[i].color,
        text: {
          // Since the values should not overlap, we subract 0.1 from the value in the legend
          label: `<${colorValues[0].temperature}`,
          ariaLabel: translate('map/dataTemperatureLegend/temperatureStartLong', {
            temperature: colorValues[0].temperature
          })
        }
      });
      continue;
    }

    const fromValue = colorValues[i].temperature;
    processedData.push({
      color: colorValues[i].color,
      text: {
        label: `${fromValue}`,
        ariaLabel: translate('map/dataTemperatureLegend/temperatureFromToLong', {
          temperatureFrom: colorValues[i].temperature,
          temperatureTo: colorValues[i + 1].temperature
        })
      }
    });
  }

  return processedData;
}
