import { Icon, TIconId } from '@nrk/yr-icons';
import { Abbreviation } from '../Abbreviation/Abbreviation';
import { Text } from '../Text/Text';
import './MapDataLegend.scss';

export interface IMapDataLegendValues {
  color: string;
  text?: {
    label: string;
    ariaLabel?: string;
  };
}

interface IProps {
  icondId: TIconId;
  label: string;
  ariaLabel: string;
  values: IMapDataLegendValues[];
  bottomText?: string;
}

export function MapDataLegend(props: IProps) {
  const { values, icondId, label, ariaLabel, bottomText } = props;

  return (
    <Text as="div" size="5" className="map-data-legend-container">
      <div className="map-data-legend">
        <div className="map-data-legend__label">
          <Icon className="map-data-legend__label-icon" id={icondId} size={2} />
          <Abbreviation title={ariaLabel} abbreviation={label} />
        </div>

        <div className="map-data-legend__values">
          <div className="map-data-legend__values-color-palet">
            {values.map((value, index) => {
              return (
                <div
                  key={index}
                  className="map-data-legend__values-color"
                  data-index={index === 0 ? 'start' : index === values.length - 1 ? 'end' : null}
                  style={{ backgroundColor: value.color }}
                ></div>
              );
            })}
          </div>

          <ol className="map-data-legend__value-labels">
            {values.map((value, index) => {
              return (
                value.text != null && (
                  <li key={index} className="map-data-legend__value-label">
                    <span aria-hidden={value.text.ariaLabel != null}>{value.text.label}</span>
                    {value.text.ariaLabel != null && <span className="nrk-sr">{value.text.ariaLabel}</span>}
                  </li>
                )
              );
            })}
          </ol>
        </div>
      </div>
      {bottomText && <span className="map-data-legend__bottom-text">{bottomText}</span>}
    </Text>
  );
}
