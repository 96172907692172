import { IRadarLayerEvent, RadarLayer } from '@nrk/yr-map';
import yrTime from '@nrk/yr-time';
import { useEffect, useMemo, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { track } from '../../lib/analytics/track';
import { createTimeLabel } from '../../lib/helpers/time';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IPlayerTime, MapPlayer } from '../MapPlayer/MapPlayer';

import './MapRadarToolbar.scss';
interface IProps {
  layer: RadarLayer;
  initialIndexIsNow: boolean;
}

export function MapRadarToolbar(props: IProps) {
  const { layer, initialIndexIsNow } = props;

  const translate = useTranslate();
  const { currentPage } = useAppState();
  const { embedded } = currentPage.details.query;
  const [currentFrameIndex, setCurrentFrameIndex] = useState<number>(layer.getCurrentFrameIndex());
  const [initialIndex, setInitialIndex] = useState<number>(layer.getInitialIndex());
  const [frames, setFrames] = useState<string[]>(layer.getFrames());
  const [isPlaying, setIsPlaying] = useState(layer.getIsPlaying());
  const [hasTrackedChooseTime, setHasTrackedChooseTime] = useState(false);

  const [times, setTimes] = useState<IPlayerTime[]>();
  const [newStartLabel, setNewStartLabel] = useState<string>();
  const [newEndLabel, setNewEndLabel] = useState<string>();

  useMemo(() => {
    // We are using the user's local time to display labels in the radar toolbar
    // Since data from the radar api is in UTC we are adding an offset based on user's local time
    const clientTimeOffset = yrTime.create().offset();
    const times: IPlayerTime[] = frames.map((frame, index) => {
      const time = yrTime.create(frame).offset(clientTimeOffset);

      const label =
        embedded === true
          ? createTimeLabel({
              translate,
              time: time.timeString,
              type: 'date-with-time-short',
              transform: 'sentence-case'
            })
          : time.format(translate('units/timeformat'));
      const ariaLabel = embedded === true ? label : translate('grammar/timeLabel', { time: label });
      return {
        label,
        ariaLabel,
        time: time.timeString,
        tick: index === layer.getInitialIndex() ? 'long' : 'short'
      };
    });

    setTimes(times);

    const lastFrameIndex = frames.length - 1;
    const nowTime = yrTime.create(times[initialIndex].time);
    const firstTime = yrTime.create(times[0].time);
    const lastTime = yrTime.create(times[lastFrameIndex].time);

    let oldestTimeDiff = firstTime.diff(nowTime, 'minutes');
    let newestTimeDiff = lastTime.diff(nowTime, 'minutes');

    const fromLabel = `-${Math.abs(oldestTimeDiff)} ${translate('units/time/minutes/short')}.`;
    const toLabel = `+${Math.abs(newestTimeDiff)} ${translate('units/time/minutes/short')}.`;

    setNewStartLabel(fromLabel);
    setNewEndLabel(toLabel);
  }, [frames, initialIndex, translate, embedded, layer]);

  useEffect(() => {
    function handleEvent(event: IRadarLayerEvent) {
      if (event.type === 'radar-layer:current-frame') {
        setCurrentFrameIndex(event.index);
      }

      if (event.type === 'radar-layer:is-playing') {
        setIsPlaying(event.value);
      }

      if (event.type === 'radar-layer:frames') {
        setFrames(event.times);
        setInitialIndex(event.initialFrameIndex);
      }
    }

    layer.addEventListener(handleEvent);

    return () => {
      layer.removeEventListener(handleEvent);
    };
  }, [layer]);

  // Do not render the toolbar until we have data from Map events
  if (
    frames == null ||
    currentFrameIndex == null ||
    isPlaying == null ||
    newStartLabel == null ||
    newEndLabel == null ||
    times == null
  ) {
    return null;
  }

  return (
    <div data-player-type={embedded === true ? 'mini' : 'normal'} className="map-radar-toolbar">
      <MapPlayer
        startLabel={newStartLabel}
        times={times}
        endLabel={newEndLabel}
        isPlaying={isPlaying}
        currentTimeIndex={currentFrameIndex}
        nowTimeIndex={initialIndex}
        type="radar"
        playerType={embedded === true ? 'mini' : 'normal'}
        shouldRenderBottomLabels={initialIndexIsNow === true}
        onChange={frameIndex => {
          // Track the first time someone interacts with the player,
          // and they actually change the value and not just hover on the same spot.
          if (hasTrackedChooseTime === false && currentFrameIndex !== frameIndex) {
            setHasTrackedChooseTime(true);
            track.event({ category: 'map_radar', action: 'choose_time' });
          }

          layer.setSourceIndex(frameIndex);
          layer.pause();
        }}
        onPlay={() => {
          track.event({ category: 'map_radar', action: 'play' });

          layer.play();
        }}
        onPause={() => {
          track.event({ category: 'map_radar', action: 'pause' });

          layer.pause();
        }}
      />
    </div>
  );
}
