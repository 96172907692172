import { IApiTileData } from '@nrk/yr-map';
import yrTime from '@nrk/yr-time';
import { IAPIMapFeature } from '../../model/api/mapFeature';
import { LocaleCode } from '../../model/locale';
import { IMapFeature } from '../../model/mapFeature';
import { IApiLightningData } from './queries';

export function parseMapFeature({ localeCode, data }: { localeCode: LocaleCode; data: IAPIMapFeature }): IMapFeature {
  return {
    id: data.properties.id,
    elevation: data.properties.elevation,
    weight: data.properties.elevation,
    name: data.properties.names[localeCode],
    forecast: data.properties.forecast
  };
}

export function parseLightning({ data }: { data: IApiLightningData }) {
  // We get the historical lightning data in a string of an array of arrays with this format:
  // 0: unix timestamp
  // 1: longitude
  // 2: latitude
  // 3: sky-to-sky (either 1 or 0, sky-to-sky is 1)
  return JSON.parse(data.historicalData);
}

export function parseWind({ data }: { data: IApiTileData }) {
  // We don't want to show intervals that is older then 1 hour, so we subtract one hour from the current client time
  const clientTimeMinusOneHour = yrTime
    .create()
    .now()
    .subtract(1, 'hours');

  // Filter the times array from api to only contain data newer than one hour
  const timesNewerThanOneHour = data.times.filter(timeObject => {
    const time = yrTime.create(timeObject.time);

    return time.isAfter(clientTimeMinusOneHour, 'minute');
  });

  return { ...data, times: timesNewerThanOneHour };
}

export function parseTemperature({ data }: { data: IApiTileData }) {
  // We don't want to show intervals that is older then 1 hour, so we subtract one hour from the current client time
  const clientTimeMinusOneHour = yrTime
    .create()
    .now()
    .subtract(1, 'hours');

  // Filter the times array from api to only contain data newer than one hour
  const timesNewerThanOneHour = data.times.filter(timeObject => {
    const time = yrTime.create(timeObject.time);

    return time.isAfter(clientTimeMinusOneHour, 'minute');
  });

  return { ...data, times: timesNewerThanOneHour };
}
