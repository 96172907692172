import { ILightningLayerEvent, LightningLayer } from '@nrk/yr-map';
import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableButton } from '../Clickable/Clickable';
import './MapLightningToolbar.scss';

interface IProps {
  layer: LightningLayer;
}

export function MapLightningToolbar(props: IProps) {
  const { layer } = props;

  const { currentPage } = useAppState();
  const { embedded } = currentPage.details.query;
  const [activeHourIndex, setActiveHourIndex] = useState<number>(layer.getActiveHourIndex());
  const [hours, setHours] = useState<number[]>(layer.getHours());

  const translate = useTranslate();

  useEffect(() => {
    function handleEvent(event: ILightningLayerEvent) {
      if (event.type === 'lightning-layer:active-hour-index') {
        setActiveHourIndex(event.index);
      }

      if (event.type === 'lightning-layer:hours') {
        setHours(event.hours);
      }
    }

    layer.addEventListener(handleEvent);

    return () => {
      layer.removeEventListener(handleEvent);
    };
  }, [layer]);

  // We do not want to show the toolbar when the map is embedded in articles at nrk.no.
  // See https://nrk.enterprise.slack.com/archives/C0796EEJLCR/p1719482594794849?thread_ts=1719389837.499949&cid=C0796EEJLCR
  if (embedded === true) {
    return null;
  }

  const toolbarHours: { hour: number; label: string }[] = hours.map(hour => {
    if (hour === 1) {
      return { hour, label: translate('mapPage/lightningToolbar/lastHour') };
    } else {
      return { hour, label: translate('mapPage/lightningToolbar/lastHours', { hour }) };
    }
  });

  return (
    <div className="map-lightning-toolbar">
      {toolbarHours.map((hours, index) => {
        const isActive = index === activeHourIndex;

        return (
          <ClickableButton
            className="map-lightning-toolbar__button"
            aria-pressed={isActive}
            disabled={isActive}
            key={hours.hour}
            analytics={{ category: 'map_lightning', action: 'choose_time', value: hours.hour }}
            onClick={() => {
              layer.setActiveHourIndex(index);
            }}
          >
            {hours.label}
          </ClickableButton>
        );
      })}
    </div>
  );
}
