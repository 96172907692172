// Height of each y axis row in the statistics graphs in that graph's unit
export const GRAPH_Y_AXIS_STRIDE = {
  month: {
    temperature: 4,
    precipitation: 30,
    snowDepth: 10,
    wind: 3
  },
  day: {
    temperature: 4,
    precipitation: 30,
    snowDepth: 10,
    wind: 3
  },
  hour: {
    temperature: 1,
    precipitation: 5,
    snowDepth: 10,
    wind: 3
  },
  coast: {
    current: 20,
    temperature: 2,
    waveHeight: 1,
    waterLevel: 20,
    wind: 4
  },
  detailed: {
    temperature: 2,
    wind: 4,
    precipitation: 2,
    pressure: 10,
    humidity: 10
  },
  twentyOneDayForecast: {
    precipitation: 20,
    temperature: 5
  },
  airQuality: 1
};

// Minimum number of rows in a statistics graph
export const STATISTICS_GRAPH_Y_AXIS_MIN_ROWS = 3;
