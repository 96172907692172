import { useTranslate } from '../../lib/hooks/useTranslate';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { Text } from '../Text/Text';
import './MapLightningInformationDialog.scss';

interface IProps {
  onClose: () => void;
}

export function MapLightningInformationDialog(props: IProps) {
  const { onClose } = props;
  const translate = useTranslate();

  const scaleValues = [
    { color: '#FF7300', label: translate('mapPage/lightning/legend/1') },
    { color: '#AE5EED', label: translate('mapPage/lightning/legend/2') },
    { color: '#C795EF', label: translate('mapPage/lightning/legend/3') },
    { color: '#BCADC7', label: translate('mapPage/lightning/legend/4') },
    { color: '#DDDAE0', label: translate('mapPage/lightning/legend/5') }
  ];

  return (
    <ModalDialog
      testId="map-lightning-info-dialog"
      renderOnServer={false}
      onClose={onClose}
      size="small"
      title={translate('mapLightningInformationDialog/informationLabel')}
    >
      <div className="map-lightning-information-dialog" role="presentation">
        <p className="map-lightning-information-dialog__lead">{translate('mapPage/lightning/legend/lead')}</p>
        <Text as="p" size="4" weight="bold" className="map-lightning-information-dialog__scale-heading">
          {translate('mapPage/lightning/legend/scaleHeading')}
        </Text>
        <ol className="map-lightning-information-dialog__scale-gradient">
          {scaleValues.map(value => (
            <li
              className="map-lightning-information-dialog__scale-gradient-value"
              key={`${value.color}-${value.label}`}
            >
              <div
                style={{ backgroundColor: value.color }}
                className="map-lightning-information-dialog__scale-gradient-color"
              ></div>
              <p className="map-lightning-information-dialog__scale-gradient-color-label">{value.label}</p>
            </li>
          ))}
        </ol>
      </div>
    </ModalDialog>
  );
}
