import settings from '../../app/settings';

export function getZoomLevelsForLayer({
  embedded,
  layer,
  zoom,
  locationId
}: {
  embedded: boolean;
  layer: 'radar' | 'lightning' | 'temperature' | 'wind' | 'weather';
  zoom?: number;
  locationId?: string;
}) {
  const zoomLevels = locationId != null ? settings.map.zoomLevels[layer] : settings['standalone-map'].zoomLevels[layer];

  // nrk.no wants to have the possibility to choose the zoom.
  if (embedded === true && zoom != null && locationId != null) {
    return {
      initial: zoom,
      min: zoomLevels.min,
      max: zoomLevels.max
    };
  }

  return zoomLevels;
}
