interface IProps {
  title: string;
  abbreviation: string;
}

export function Abbreviation(props: IProps) {
  const { title, abbreviation } = props;

  if (title === abbreviation) {
    return <>{title}</>;
  }

  return <abbr title={title}>{abbreviation}</abbr>;
}
